import { defineStore } from 'pinia'
import useAuthStore from './authStore'

const accountApiConfig = {
    baseUrl: `${process.env.VUE_APP_BASE_API_URL}/api/accounts`,
}

export default defineStore('accountStore', {
    state: () => ({
        accounts: [],
        selectedAccount: null
    }),
    getters: {
        getAccounts: state => state.accounts,
    },
    actions: {
        setSelectedAccount(accountName) {
            this.selectedAccount = accountName;
        },
        async fetchAccounts() {
            const authStore = useAuthStore();
            const accessToken = await authStore.getTokenRedirect();

            try {
                const options = {
                    method: 'GET',
                    headers: { 'Authorization': `Bearer ${accessToken}` }
                }

                const response = await fetch(accountApiConfig.baseUrl, options);
                this.accounts = await response.json();
            } catch (error) {
                console.error(error);
            }
        },
        async createAccount(accountName) {
            const authStore = useAuthStore();
            const accessToken = await authStore.getTokenRedirect();

            try {
                const options = {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ name: accountName })
                }

                const response = await fetch(accountApiConfig.baseUrl, options);
                // const newAccount = await response.json();
                // this.accounts.push(newAccount);
                return response;
            } catch (error) {
                console.error(error);
            }
        }
    },
})