<template>
  <v-app class="logged-out">
    <v-container fill-height fluid class="home-container">
      <v-row align="center" justify="center">
        <v-card color="#020240" dark width="400px" elevation="5">
          <v-card-title>
            <img id="logo" src="../../assets/logo.png" width="300px" />
          </v-card-title>

          <v-card-text class="text-h6 mt-4" align="center">
            <v-btn @click="SignIn()" x-large color="success" dark>
              <v-icon left> mdi-login </v-icon> Sign In {{ counter }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-row>
      <v-row class="privacy-cgu-row">
        <span class="
        blue--text
        text--darken-4
        font-weight-black
        cursor-pointer
        cgu
        "
        @click="cguRedirect()">Conditions of use</span>
        <span class="
        blue--text
        text--darken-4
        font-weight-black
        cursor-pointer
        privacy
        "
        @click="privacyRedirect()">Privacy policy</span>
        <span class="
        blue--text
        text--darken-4
        font-weight-black
        cursor-pointer
        privacy
        "
        @click="how2startRedirect()">How to start</span>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import useAuthStore from "../../stores/authStore";
// import { AuthService } from "../../services/AuthService";
export default defineComponent({
  name: "Home",
  components: {},
  setup() {
    const authStore = useAuthStore();

    return {
      authStore: authStore,
    };
  },
  computed: {
    counter() {
      if (this.authStore
      && this.authStore.counter === undefined){
        return this.authStore.counter;
      }else{
        return null;
      }
    },
  },
  data: function () {
    return {
      account: undefined,
      // authService: null,
    };
  },
  async created() {},
  async mounted() {
    // this.authService = new AuthService(this.$$msalInstance);
    this.authStore.counter++; 
  },
  methods: {
    async SignIn() {
      this.authStore.login();
    },
    cguRedirect(){
      this.$router.push("/cgu");
    },
    privacyRedirect(){
      this.$router.push("/privacy");
    },
    how2startRedirect(){
      this.$router.push("/help");
    },
  },
});
</script>
<style>
.cursor-pointer:hover{
  cursor: pointer;
}
.home-container{
  margin-top: 8%;
}
.privacy-cgu-row{
  padding-top: 17%;
}
.privacy{
  margin-left: 2%;
}
.cgu{
  margin-left: 1%;
}
</style>