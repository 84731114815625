import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/lobby',
        name: 'lobby',
        component: () => import('../views/Home/Lobby.vue')
    },
    {
        path: '/:accountName/settings',
        name: 'Settings',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Settings/Settings.vue')
    },
    {
        path: '/:accountName/audits',
        name: 'Audits',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Audits/AuditList.vue')
    },
    {
        path: '/:accountName/audits/new',
        name: 'AuditsCreate',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Audits/AuditCreate.vue')
    },
    {
        path: '/:accountName/audits/:auditId',
        name: 'AuditsDetails',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Audits/AuditDetails.vue')
    },
    {
        path: '/:accountName/admin',
        name: 'Admin',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Admin/Admin.vue'),
        meta: { admin: true }
    },
    {
        path: '/:accountName/cgu',
        name: 'CGU',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Pages/CGU.vue')
    },
    {
        path: '/cgu',
        name: 'CGU_unlogged',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Pages/CGU.vue')
    },
    {
        path: '/:accountName/privacy',
        name: 'Privacy',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Pages/Privacy.vue')
    },
    {
        path: '/privacy',
        name: 'Privacy_unlogged',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Pages/Privacy.vue')
    },
    {
        path: '/:accountName/help',
        name: 'Help',
        component: () => import('../views/Pages/Help.vue')
    },
    {
        path: '/help',
        name: 'Help_unlogged',
        component: () => import('../views/Pages/Help.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
