<template>
  <router-view />
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import useAuthStore from "./stores/authStore";
import useAccountStore from "./stores/accountStore";

export default defineComponent({
  setup() {
    const authStore = useAuthStore();
    const accountStore = useAccountStore();

    return {
      authStore: authStore,
      accountStore: accountStore,
    };
  },

  async mounted() {
    await this.authStore.initStore();
    setTimeout(()=>{
        if(this.authStore['account']){
          if(!this.$route.params.accountName && this.$router.history.current.name !== "lobby"){
            this.$router.replace(`/lobby`);  
          }
        }else{
          if (!this.location.pathname 
            && this.location.pathname != undefined){
              this.$router.replace(this.location.pathname);
          }
          else if(this.$router.history.current.name !== "Home"){
            this.$router.replace("/");
          }
        }
    },150)

    if (this.$route.params.accountName) {
      this.accountStore.setSelectedAccount();
    }
  },
});
</script>


<style lang="scss">
.theme--light.v-application.logged-out {
  background: url("/bg.png");
}

html {
  scroll-behavior: smooth;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #020240; 
}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #00c27a;
  color: #00c27a;
  box-shadow: 9999px 0 0 -5px #00c27a;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #00c27a;
  color: #00c27a;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px #00c27a;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px #00c27a;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #00c27a;
  }
  30% {
    box-shadow: 9984px 0 0 2px #00c27a;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #00c27a;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px #00c27a;
  }
  30% {
    box-shadow: 9999px 0 0 2px #00c27a;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #00c27a;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #00c27a;
  }
  30% {
    box-shadow: 10014px 0 0 2px #00c27a;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #00c27a;
  }
}
</style>