import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
import { createPinia, PiniaVuePlugin } from 'pinia'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'

Vue.config.productionTip = false
Vue.use(VueCompositionApi)
Vue.use(PiniaVuePlugin)
const pinia = createPinia();

Vue.prototype.$msalInstance = {};

export const vue = new Vue({
  pinia,
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
